const login = (username, password) => {
  if (username === 'singlifeph' && password === '!singlife01') {
    sessionStorage.setItem('logged_in', true);
    return true;
  }
  return false;
};

const isLoggedIn = () => {
  if (sessionStorage.getItem('logged_in')) {
    return true;
  }
  return false;
};

export default {
  login,
  isLoggedIn,
};
