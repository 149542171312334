import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, InputAdornment, Grid, TextField as MuiTextField, Slide, Fade
} from '@material-ui/core';

import CardUtils from 'src/utils/CardUtils';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    minHeight: '11rem',
    padding: '0',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2.25rem',
    },
  },
  item: {
    position: 'relative',
    height: '90px',
    minHeight: '90px',
    maxHeight: '90px',
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
  input: {
    fontSize: '14px !important',
    lineHeight: 16,
    fontWeight: 500,
  },
  helperText: {
    fontSize: '10px',
  },
  brandIcons: {
    position: 'absolute',
    top: '0',
    right: '10px',
    height: '100%',
    overflow: 'hidden',
  },
  brandIconsRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'flex-end',
    paddingBottom: '40px'
  },
  brandIcon: {
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    zIndex: 3,
    paddingRight: '4px',
    img: {
      height: '16px',
    }
  },
  currentBrand: {
    position: 'absolute',
    top: '0',
    right: '10px',
    height: '100%',
    overflow: 'hidden',
  },
  currentBrandIcon: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'flex-end',
    paddingBottom: '40px',
    pointerEvents: 'none',
    zIndex: 3,
    paddingRight: '4px',
    img: {
      height: '16px',
    }
  },
}));

const cvcIcon = (
  <InputAdornment position="end">
    <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 21" style={{ height: '14px' }}>
      <g fill="none" fillRule="evenodd">
        <g style={{ fill: '#565656' }}>
          <g transform="translate(0 2)">
            <path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2" />
            <path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3" />
          </g>
          <g transform="translate(18)"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z" /></g>
        </g>
      </g>
    </svg>
  </InputAdornment>
);

const TextField = ({ rightIcon, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiTextField
      InputLabelProps={{ className: classes.label }}
      InputProps={{ className: classes.input, endAdornment: rightIcon }}
      FormHelperTextProps={{ className: classes.helperText }}
      fullWidth
      color="secondary"
      autoComplete="off"
      {...rest}
    />
  );
};

TextField.propTypes = {
  rightIcon: PropTypes.node,
};

const CardInputForm = ({
  showBrandIcons = true,
  brand,
  cardNumber,
  cardNumberError,
  onCardNumberBlur,
  onCardNumberKeyPressed,
  expiry,
  expiryError,
  onExpiryBlur,
  onExpiryChanged,
  cvv,
  cvvError,
  onCvvBlur,
  onCvvKeyPressed,
  cardholder,
  cardholderError,
  onCardholderBlur,
  onCardholderChanged,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.item}>
          <TextField
            error={!!cardNumberError}
            helperText={cardNumberError || ''}
            type="text"
            label="Card Number"
            value={cardNumber || ''}
            inputProps={{ maxLength: 19, pattern: '^[\\d ]*$', inputMode: 'numeric' }}
            onKeyDown={(e) => onCardNumberKeyPressed(e)}
            onBlur={(e) => onCardNumberBlur(e)}
          />
          <div className={classes.brandIcons}>
            <Slide
              in={showBrandIcons}
              direction="left"
              timeout={{ enter: 500, exit: 300 }}
            >
              <div className={classes.brandIconsRow}>
                <div>
                  <span className={classes.brandIcon}>
                    <img src="/images/visa.svg" alt="Visa" />
                  </span>
                </div>
                <div>
                  <span className={classes.brandIcon}>
                    <img src="/images/mastercard.svg" alt="Mastercard" />
                  </span>
                </div>
              </div>
            </Slide>
          </div>
          <div className={classes.currentBrand}>
            <Fade
              in={!!brand}
              timeout={{ enter: 300, exit: 450 }}
            >
              <div className={classes.currentBrandIcon}>
                <img src={CardUtils.getBrandIcon(brand)} alt={brand} />
              </div>
            </Fade>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            error={!!expiryError}
            helperText={expiryError || ''}
            type="text"
            label="Expiry Date"
            value={expiry || ''}
            inputProps={{ maxLength: 5 }}
            onChange={(e) => onExpiryChanged(e)}
            onBlur={(e) => onExpiryBlur(e)}
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            error={!!cvvError}
            helperText={cvvError || ''}
            type="text"
            label="CVV"
            rightIcon={cvcIcon}
            value={cvv || ''}
            inputProps={{ maxLength: 4, pattern: '^[0-9]*$' }}
            onBlur={(e) => onCvvBlur(e)}
            onKeyDown={(e) => onCvvKeyPressed(e)}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <TextField
            error={!!cardholderError}
            helperText={cardholderError || ''}
            type="text"
            label="Card Holder Name"
            autoComplete="name"
            value={cardholder || ''}
            onChange={(e) => onCardholderChanged(e)}
            onBlur={(e) => onCardholderBlur(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CardInputForm.propTypes = {
  showBrandIcons: PropTypes.bool,
  brand: PropTypes.string,
  cardNumber: PropTypes.string,
  cardNumberError: PropTypes.string,
  onCardNumberBlur: PropTypes.func,
  onCardNumberKeyPressed: PropTypes.func,
  expiry: PropTypes.string,
  expiryError: PropTypes.string,
  onExpiryBlur: PropTypes.func,
  onExpiryChanged: PropTypes.func,
  cvv: PropTypes.string,
  cvvError: PropTypes.string,
  onCvvBlur: PropTypes.func,
  onCvvKeyPressed: PropTypes.func,
  cardholder: PropTypes.string,
  cardholderError: PropTypes.string,
  onCardholderBlur: PropTypes.func,
  onCardholderChanged: PropTypes.func,
};

export default CardInputForm;
