import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  content: {
    color: theme.palette.grey[900],
    width: '100%',
    flex: '1 0 auto',
    padding: '0',
    marginLeft: 'auto',
    marginRight: '0',
  },
}));

const MainLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Container className={classes.content}>
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

export default MainLayout;
