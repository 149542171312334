import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container, makeStyles } from '@material-ui/core';

import AuthUtils from 'src/utils/AuthUtils';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  content: {
    color: theme.palette.grey[900],
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '3rem',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: '1 0 auto',
    marginTop: '75px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '2rem',
      maxWidth: '540px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '960px'
    },
  },
}));

const MainLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!AuthUtils.isLoggedIn()) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <TopBar />
        <Container className={classes.content}>
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

export default MainLayout;
