import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  makeStyles, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Button, Dialog
} from '@material-ui/core';
import {
  PhoneRounded,
  AssignmentIndRounded,
  FaceRounded,
} from '@material-ui/icons';
import queryString from 'query-string';

import Page from 'src/components/Page';
import Header from 'src/components/Header';
import Magpie from 'src/api/Magpie';

const useStyles = makeStyles(({
  contentBody: {
    width: '100%',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  product: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    border: '1px solid #d9e1ee',
    borderRadius: '8px',
    maxHeight: '160px',
    marginTop: '1rem',
  },
  productImg: {
    width: '150px',
    height: 'auto',
    borderRight: '1px solid #d9e1ee',
  },
  productDetails: {
    flex: '1 1 auto',
    padding: '1rem 1.5rem',
  },
  productTitle: {
    fontWeight: 400,
  },
  productPrice: {
    fontWeight: 600,
    marginTop: '0.75rem',
  },
  payment: {
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  paymentType: {
    fontWeight: '500',
  },
  paymentDetails: {
    textAlign: 'right',
  },
  paymentBrand: {
    fontWeight: '600',
    display: 'block',
  },
  paymentLast: {
    fontWeight: '600',
    display: 'block',
  },
  card: {
    borderColor: '#d9e1ee',
    backgroundColor: '#d9e1ee44',
    borderRadius: 4,
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  cardContent: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  list: {
    listStyleType: 'decimal',
    listStylePosition: 'inside',
  },
  listItem: {
    marginTop: '0.5rem'
  },
  actionContainer: {
    width: '100%',
    padding: '0',
    marginTop: '1.5rem',
  },
  btn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    padding: '12px 16px',
    marginBottom: '0.75rem'
  },
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  }
}));

const CardAuthSuccess = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(false);

  let chargeId = null;
  if (location.search) {
    chargeId = queryString.parse(location.search).charge_id;
  }
  useEffect(() => {
    const retrieveCharge = async () => {
      setLoading(true);
      try {
        const { data } = await Magpie.getCharge(chargeId);
        if ('source' in data) {
          setCard(data.source);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    retrieveCharge();
    return () => {
      setLoading(false);
    };
  }, [chargeId]);

  const onKycClick = () => {
    navigate('/purchase/review');
  };

  return (
    <Page title="Singlife - Card Authentication Success">
      <Header title="Payment Success" />
      <div className={classes.contentBody}>
        <div className={classes.product}>
          <img className={classes.productImg} src="/images/dengue-covid.png" alt="Product" />
          <div className={classes.productDetails}>
            <Typography variant="caption" className={classes.productTitle}>
              Cash for Dengue Costs (with FREE COVID-19 cover)
            </Typography>
            <Typography variant="h6" className={classes.productPrice}>
              PHP 3,250.00
            </Typography>
          </div>
        </div>
        <div className={classes.payment}>
          <Typography variant="body2" className={classes.paymentType}>Card</Typography>
          <div className={classes.paymentDetails}>
            <Typography variant="body2" className={classes.paymentBrand}>{card ? card.brand : ''}</Typography>
            <Typography variant="body2" className={classes.paymentLast}>{`**** **** **** ${card ? card.last4 : ''}`}</Typography>
          </div>
        </div>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography className={classes.cardContent} variant="caption">
              I understand that Singlife Philippines will automatically charge the
              {' '}
              initial premium to my registered card 16 days after the Policy Issuance.
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="subtitle2" align="center" component="p" style={{ marginBottom: '1.25rem' }}>
          We have secured your payment details. Your payment will only be processed
          {' '}
          once your identity has been verified.
        </Typography>
        <Typography variant="subtitle2" align="center" component="p" style={{ marginBottom: '0.5rem' }}>
          To verify your identity, we will need from you:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <PhoneRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Additional Personal &amp; Contact Details" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FaceRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Selfie" />
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ alignSelf: 'flex-start', paddingTop: '6px' }}>
              <AssignmentIndRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Valid ID"
              secondary="Make sure your personal details match your ID."
              secondaryTypographyProps={{ style: { fontSize: '12px' } }}
            />
          </ListItem>
        </List>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography className={classes.cardContent} variant="caption">
              <u>Important Notes</u>
              <ol className={classes.list}>
                <li className={classes.listItem}>
                  Ensure that the information you provided matches your valid ID;
                </li>
                <li className={classes.listItem}>
                  Further validation of your indentity or submission of additional
                  {' '}
                  documents may be required depending on your responses.
                </li>
              </ol>
            </Typography>
          </CardContent>
        </Card>
        <div className={classes.actionContainer}>
          <Button onClick={() => onKycClick()} className={classes.btn} fullWidth variant="contained" color="primary">Proceed to e-kyc</Button>
        </div>
      </div>
      <Dialog
        open={loading}
        PaperProps={{ className: classes.dialog }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
      </Dialog>
    </Page>
  );
};

export default CardAuthSuccess;
