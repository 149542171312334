import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  makeStyles, Typography, Button, Dialog
} from '@material-ui/core';
import queryString from 'query-string';

import Page from 'src/components/Page';
import Header from 'src/components/Header';
import Magpie from 'src/api/Magpie';

const useStyles = makeStyles((theme) => ({
  contentBody: {
    width: '100%',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtitle: {
    marginTop: '1rem',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  actionContainer: {
    width: '100%',
    padding: '0',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2.25rem',
    },
  },
  btn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    padding: '12px 16px',
    marginBottom: '0.75rem'
  },
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  }
}));

const BankAuthFailedView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const [last4, setLast4] = useState('');
  const [chargeId, setChargeId] = useState(undefined);
  const [message, setMessage] = useState('generic');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location) {
      if (location.state) {
        setMessage(location.state.message);
      }

      if (location.search) {
        setChargeId(queryString.parse(location.search).charge_id);
      }
    }
  }, [location]);

  useEffect(() => {
    const retrieveCharge = async () => {
      setLoading(true);
      try {
        const { data } = await Magpie.getCharge(chargeId);
        /* if ('source' in data) {
          setLast4(data.source.last4);
        } */
        if ('redirect_response' in data) {
          if (data.redirect_response.message === 'messages.transaction_pending') {
            setMessage('generic');
          } else {
            setMessage(data.redirect_response.message);
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    if (chargeId) retrieveCharge();
    return () => {
      setLoading(false);
    };
  }, [chargeId]);

  const onTryAnotherCardClicked = (e) => {
    e.preventDefault();
    navigate('/payment');
  };
  const onBackToHomeClicked = (e) => {
    e.preventDefault();
    navigate('/purchase/review');
  };

  return (
    <Page title="Singlife - Failed Authentication">
      <Header title="Card Verification Failed" />
      <div className={classes.contentBody}>
        <Typography className={classes.subtitle} variant="subtitle2">
          {
            (message === 'generic')
              ? 'Your card has been declined.'
              : message
          }
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          Please contact your bank for details.
        </Typography>
        <div className={classes.actionContainer}>
          <Button onClick={(e) => onTryAnotherCardClicked(e)} className={classes.btn} fullWidth variant="contained" color="primary">Try another card</Button>
          <Button onClick={(e) => onBackToHomeClicked(e)} className={classes.btn} fullWidth variant="text" color="primary">Return to homepage</Button>
        </div>
      </div>
      <Dialog
        open={loading}
        PaperProps={{ className: classes.dialog }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
      </Dialog>
    </Page>
  );
};

export default BankAuthFailedView;
