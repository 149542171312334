import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      backgroundColor: '#ffffff',
      height: '100%',
      width: '100%',
      fontFamily: '"Sharp Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.33333',
      color: '#000',
      textAlign: 'left',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    '@keyframes spin': {
      '0%': {
        '-webkit-transform': 'rotate(0)',
        transform: 'rotate(0)',
      },
      '100%': {
        '-webkit-transform': 'rotate(359deg)',
        transform: 'rotate(359deg)',
      }
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
