import React from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from 'src/layouts/MainLayout';
import AuthLayout from 'src/layouts/AuthLayout';
import Payment from 'src/views/payment/';
import BankAuthView from 'src/views/payment/BankAuthView';
import BankAuthFailedView from 'src/views/payment/BankAuthFailedView';
import CardAuthSuccess from 'src/views/payment/CardAuthSuccess';
import ReviewDetails from 'src/views/purchase/ReviewDetails';
import LoginView from 'src/views/auth/LoginView';

const routes = [
  {
    path: 'payment',
    element: <MainLayout />,
    children: [
      { path: '3dsecure/confirmed', element: <CardAuthSuccess /> },
      { path: '3dsecure/failed', element: <BankAuthFailedView /> },
      { path: '3dsecure', element: <BankAuthView /> },
      { path: '/', element: <Payment /> },
      { path: '*', element: <Navigate to="/" /> },
    ],

  },
  {
    path: 'purchase',
    element: <MainLayout />,
    children: [
      { path: 'review', element: <ReviewDetails /> },
      { path: '/', element: <Navigate to="review" /> },
    ],
  },
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: '*', element: <Navigate to="/" /> },
    ]
  },
  {
    path: '/',
    element: <Navigate to="/purchase/" />
  }
];

export default routes;
