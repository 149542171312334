import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Card, CardContent, Link, Button, Dialog, DialogContent,
  DialogActions, DialogContentText
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import Page from 'src/components/Page';
import Header from 'src/components/Header';
import CardInputForm from 'src/components/CardInputForm';
import Modal from 'src/components/Modal';
import CardUtils from 'src/utils/CardUtils';
import Magpie from 'src/api/Magpie';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  lock: {
    fontSize: theme.typography.subtitle1.fontSize,
    marginRight: '0.5rem',
  },
  contentBody: {
    width: '100%',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  acceptNote: {
    marginTop: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardBrand: {
    width: 'auto',
    height: 24,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  card: {
    borderColor: '#d9e1ee',
    borderRadius: 8,
  },
  declaration: {
    fontSize: '10px',
    fontWeight: '500',
  },
  list: {
    listStyleType: 'decimal',
    listStylePosition: 'inside',
  },
  listItem: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '1.5',
    marginTop: '0.5rem'
  },
  actionContainer: {
    width: '100%',
    padding: '0',
    marginTop: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2.25rem',
    },
  },
  btn: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '14px',
    padding: '12px 16px',
  },
  modalBody: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '1.5',
  },
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  },
  errorDialog: {
    padding: '1.5rem',
    borderRadius: '8px',
  },
  errorDialogTitle: {
    padding: '0',
    paddingBottom: '1rem',
    fontWeight: '600'
  },
  errorDialogContent: {
    padding: '0',
    paddingBottom: '1.5rem',
  }
}));

const formatCardNumber = (value) => {
  const format = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(format, (regex, $1, $2, $3, $4) => [$1, $2, $3, $4].filter((group) => !!group).join(' '));
};

const formatExpiry = (value) => {
  return value.replace(
    /[^0-9]/g, '' // To allow only numbers
  ).replace(
    /^([2-9])$/g, '0$1' // To handle 3 > 03
  ).replace(
    /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
  ).replace(
    /^0{1,}/g, '0' // To handle 00 > 0
  )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
};

const EmptyCardsView = ({ onAddCard }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState('');
  const [maskedCardNumber, setMaskedCardNumber] = useState('');
  const [expiry, setExpiry] = useState(undefined);
  const [cvv, setCvv] = useState('');
  const [maskedCvv, setMaskedCvv] = useState('');
  const [cardholder, setCardHolder] = useState(undefined);
  const [cardNumberError, setCardNumberError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [cardholderError, setCardholderError] = useState('');
  const [enableBtn, setEnableBtn] = useState(false);
  const [showBrandIcons, setShowBrandIcons] = useState(true);
  const [brand, setBrand] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (cardNumber !== '') {
      setShowBrandIcons(false);
    } else {
      setShowBrandIcons(true);
    }
    setBrand(CardUtils.getBrandFromCardNumber(cardNumber));
    if (cardNumber.length === 19) {
      if (CardUtils.getBrandFromCardNumber(cardNumber) === '') {
        setCardNumberError('Please enter a valid card number');
      } else {
        setCardNumberError('');
      }
    }
  }, [cardNumber]);

  const checkValidity = () => {
    if (!!cardNumber && !!expiry && !!cvv && !!cardholder) {
      if (cardNumber.length === 19 && !cardNumberError
        && expiry.length === 5 && !expiryError
        && cvv.length > 2 && !cvvError
        && cardholder.length > 0 && cardholder.trim().split(' ').length > 1
        && !cardholderError) {
        setEnableBtn(true);
      } else {
        setEnableBtn(false);
      }
    } else {
      setEnableBtn(false);
    }
  };
  const onCardNumberKeyPressed = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCardNumber(cardNumber.toString().slice(0, -1));
      setMaskedCardNumber(maskedCardNumber.toString().slice(0, -1));
    } else {
      const value = formatCardNumber(`${cardNumber}${e.key.toString()}`);
      if (cardNumber.length < 19) {
        setCardNumber(value);
        // setMaskedCardNumber(value.replace(/[^\s]/g, '•'));
        setMaskedCardNumber(value.replace(/^.{1,15}/, (m) => m.replace(/[^\s]/g, '•')));
      }
    }
    checkValidity();
  };
  const onCardNumberBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length !== 19) {
        setCardNumberError('Your card number is incomplete');
      } else {
        setCardNumberError('');
      }
    }
    checkValidity();
  };
  const onExpiryChanged = (e) => {
    setExpiry(formatExpiry(e.target.value));
    if (e.target.value.length === 5) {
      if (CardUtils.isValidExpiry(e.target.value)) {
        setExpiryError('');
      } else {
        setExpiryError('Invalid expiration date');
      }
    }
    checkValidity();
  };
  const onExpiryBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length !== 5) {
        setExpiryError('Invalid expiry date');
      }
    }
    checkValidity();
  };
  const onCvvKeyPressed = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCvv(cvv.toString().slice(0, -1));
      setMaskedCvv(maskedCvv.toString().slice(0, -1));
    } else {
      const value = `${cvv}${e.key.toString()}`;
      setCvv(value.replace(/[^\d]/g, ''));
      setMaskedCvv(value.replace(/[^\s]/g, '•'));
      if (value.length > 2) {
        setCvvError('');
      }
    }
    checkValidity();
  };
  const onCvvBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length < 3) {
        setCvvError('Invalid CVV');
      }
    }
    checkValidity();
  };
  const onCardholderChanged = (e) => {
    setCardHolder(e.target.value);
    if (e.target.value.length > 0) {
      setCardholderError('');
    }

    checkValidity();
  };
  const onCardholderBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length < 1) {
        setCardholderError('Please enter cardholder name');
      } else if (e.target.value.trim().split(' ').length < 2) {
        setCardholderError('Please enter full cardholder name');
      }
    }
    checkValidity();
  };

  const onLinkClicked = (e) => {
    e.preventDefault();
    setShowInfoModal(true);
  };
  const closeModalHandler = () => setShowInfoModal(false);
  const onSaveCard = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const card = {
        number: cardNumber.replace(/\s+/g, ''),
        name: cardholder,
        exp_month: expiry.split('/')[0],
        exp_year: `20${expiry.split('/')[1]}`,
        cvc: cvv,
      };
      const { data } = await Magpie.createToken(card);
      // console.log('response', data);
      if ('error' in data) {
        setError(data.message || data.error.message);
      } else {
        const { token } = data;
        onAddCard({
          id: token.id,
          name: token.card.name,
          last: token.card.last4,
          expiry: `${token.card.exp_month.toString()}/${token.card.exp_year.toString().substring(2)}`,
          brand: token.card.brand,
          primary: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Page title="Singlife - Payment Details">
      <Header title="Payment Details" />
      <div className={classes.subtitle}>
        <LockOutlined className={classes.lock} />
        <Typography variant="subtitle2">
          Your information is safe and secure with us.
        </Typography>
      </div>
      <div className={classes.contentBody}>
        <div className={classes.acceptNote}>
          <img className={classes.cardBrand} src="/images/mastercard.png" alt="Visa" />
          <img className={classes.cardBrand} src="/images/visa.png" alt="Mastercard" />
        </div>
        <CardInputForm
          showBrandIcons={showBrandIcons}
          brand={brand}
          cardNumber={maskedCardNumber}
          cardNumberError={cardNumberError}
          onCardNumberBlur={onCardNumberBlur}
          onCardNumberKeyPressed={onCardNumberKeyPressed}
          expiry={expiry}
          expiryError={expiryError}
          onExpiryBlur={onExpiryBlur}
          onExpiryChanged={onExpiryChanged}
          cvv={maskedCvv}
          cvvError={cvvError}
          onCvvBlur={onCvvBlur}
          onCvvKeyPressed={onCvvKeyPressed}
          cardholder={cardholder}
          cardholderError={cardholderError}
          onCardholderBlur={onCardholderBlur}
          onCardholderChanged={onCardholderChanged}
        />
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography className={classes.declaration} variant="caption">By clicking Confirm,</Typography>
            <ol className={classes.list}>
              <li className={classes.listItem}>
                I agree to the
                {' '}
                <Link href="#" onClick={onLinkClicked}>Payment Conditions</Link>
                {' '}
                and authorize Singlife Philippines to automatically charge the
                {' '}
                initial premium to my registered card 16 days after the Policy Issuance.
              </li>
              <li className={classes.listItem}>
                I confirm that the name on the card and I are the same person.
              </li>
            </ol>
          </CardContent>
        </Card>
        <div className={classes.actionContainer}>
          <Button disabled={!enableBtn} className={classes.btn} fullWidth variant="contained" color="primary" onClick={(e) => onSaveCard(e)}>Confirm</Button>
        </div>
      </div>
      <Modal
        open={showInfoModal}
        title="Payment Conditions"
        showClose
        onClose={closeModalHandler}
      >
        <p className={classes.modalBody}>
          I authorize Singlife Philippines to charge initial and all succeeding premiums
          {' '}
          automatically from my registered card on the premium due dates for this Product.
          {' '}
          In case of unsuccessful collection on the due date, I authorize Singlife Philippines
          {' '}
          to retry charging the premiums from my registered card within the
          {' '}
          grace period of the Product.
        </p>
      </Modal>
      <Dialog
        open={loading}
        PaperProps={{ className: classes.dialog }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
      </Dialog>
      <Dialog
        open={!!error}
        onClose={() => setError('')}
        PaperProps={{ className: classes.errorDialog }}
      >
        <Typography variant="h6" className={classes.errorDialogTitle}>Oops!</Typography>
        <DialogContent className={classes.errorDialogContent}>
          <DialogContentText variant="body1">{error}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '0' }}>
          <Button className={classes.btn} onClick={() => setError('')} color="primary" variant="contained">Try again</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

EmptyCardsView.propTypes = {
  onAddCard: PropTypes.func.isRequired,
};

export default EmptyCardsView;
