import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, makeStyles, Typography
} from '@material-ui/core';

import CardUtils from 'src/utils/CardUtils';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '0.75rem',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5rem',
  },
  details: {
    flex: '1 0 auto',
  },
  brand: {
    width: 44,
    height: 'auto',
  },
  order: {
    display: 'block',
    fontSize: '10px',
    fontWeight: '700',
    color: theme.palette.grey[600],
    textTransform: 'uppercase',
    letterSpacing: '0.8',
    marginBottom: '0.375rem',
  },
  cardNumber: {
    fontWeight: '500',
  },
  name: {
    fontSize: '10px',
  },
}));

const CardItem = ({
  id, last, name, brand, isPrimary, selected = false, onCardClick, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      style={selected ? { border: '1px solid #ff0000' } : undefined}
      onClick={() => onCardClick(id)}
      {...rest}
    >
      <CardContent className={classes.content}>
        <div className={classes.details}>
          <Typography variant="caption" className={classes.order}>
            { isPrimary ? 'Primary card' : 'Secondary card' }
          </Typography>
          <Typography variant="subtitle2" className={classes.cardNumber}>
            {`**** **** **** ${last}`}
          </Typography>
          <Typography variant="subtitle2" className={classes.name}>
            {name}
          </Typography>
        </div>
        <img className={classes.brand} src={CardUtils.getBrandImage(brand)} alt={brand} />
      </CardContent>
    </Card>
  );
};

CardItem.propTypes = {
  id: PropTypes.string.isRequired,
  last: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  onCardClick: PropTypes.func,
};

export default CardItem;
