import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import Lottie from 'react-lottie-player';

import logoJson from 'src/animations/logo.json';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '960px'
    },
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} position="fixed" elevation={0} variant="outlined" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/" style={{ outline: 'none' }}>
          {/* <span>
            <img height="42" src="/static/media/SingLifeLogo.png" alt="Singlife" />
          </span> */}
          <Lottie
            loop
            animationData={logoJson}
            play
            renderer="svg"
            rendererSettings={{
              preserveAspectRation: 'xMidYMid meet'
            }}
            style={{ maxWidth: 150, minHeight: 50 }}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
