import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as MuiModal, Slide, Card, CardContent, IconButton, Typography, makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
  },
  modalCard: {
    width: '100%',
    maxWidth: '540px',
    margin: '1rem',
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      margin: '1.75rem auto',
    },
  },
  modalContent: {
    padding: '1.5rem',
    paddingBottom: '2.5rem !important',
    position: 'relative',
  },
  modalClose: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: theme.palette.grey[800]
  },
  modalTitle: {
    display: 'block',
    fontWeight: '600',
    marginBottom: '1.25rem',
  },
}));

const Modal = ({
  open, title, showClose = false, onClose, children, ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiModal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal"
      className={classes.modal}
      {...rest}
    >
      <Slide in={open} direction="down" timeout={300}>
        <Card className={classes.modalCard}>
          <CardContent className={classes.modalContent}>
            {
              showClose && (
              <IconButton onClick={() => onClose()} className={classes.modalClose} aria-label="close">
                <Close />
              </IconButton>
              )
            }
            {
              !!title && <Typography variant="h6" className={classes.modalTitle}>{title}</Typography>
            }
            { children }
          </CardContent>
        </Card>
      </Slide>
    </MuiModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Modal;
