import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, Grid, makeStyles, TextField, Typography, Button, Dialog,
  DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';

import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import AuthUtils from 'src/utils/AuthUtils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  root: {
    height: '100%',
    backgroundColor: '#fff',
    backgroundImage: 'url("/images/red-strips.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 60px',
    backgroundSize: '70% auto',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '50% auto',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '40% auto',
    },
  },
  container: {
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1140px'
    },
  },
  appbar: {
    backgroundColor: 'transparent',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  logo: {
    height: '50px',
  },
  content: {
    padding: '100px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '150px 0 100px',
    },
  },
  title: {
    display: 'block',
    color: '#000',
    textAlign: 'left',
    fontSize: '48px',
    fontWeight: '600',
    lineHeight: '1.5',
    margin: '40px 0 80px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
  },
  input: {
    display: 'block',
    marginBottom: '15px',
    width: '80%',
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
  },
  btn: {
    marginTop: '20px',
    minWidth: '170px',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '14px',
    padding: '15px 20px',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    borderRadius: '4px',
    letterSpacing: '0.08em',
  },
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  },
  errorDialog: {
    padding: '1.5rem',
    borderRadius: '8px',
  },
  errorDialogTitle: {
    padding: '0',
    paddingBottom: '1rem',
    fontWeight: '600'
  },
  errorDialogContent: {
    padding: '0',
    paddingBottom: '1.5rem',
  },
  dialogBtn: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '14px',
    padding: '12px 16px',
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef !== null) clearTimeout(timerRef.current);
    };
  }, [timerRef]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onLoginClicked = (e) => {
    e.preventDefault();
    setLoading(true);
    const timer = setTimeout(() => {
      if (AuthUtils.login(username, password)) {
        setLoading(false);
        navigate('/purchase/review', { replace: true });
      } else {
        setLoading(false);
        setError('Invalid username and password.');
      }
    }, 500);
    timerRef.current = timer;
  };

  return (
    <Page title="Singlife - Login" className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <AppBar className={classes.appbar} position="relative" elevation={0}>
              <Toolbar className={classes.toolbar}>
                <RouterLink to="/" style={{ outline: 'none' }}>
                  <Logo className={classes.logo} />
                </RouterLink>
              </Toolbar>
            </AppBar>
          </div>
          <div className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Typography variant="h2" className={classes.title}>
                  Log in with your Singlife ID
                </Typography>
                <TextField fullWidth label="Username" className={classes.input} value={username || ''} onChange={(e) => onUsernameChanged(e)} />
                <TextField fullWidth label="Password" type="password" className={classes.input} value={password || ''} onChange={(e) => onPasswordChanged(e)} />
                <Button className={classes.btn} size="large" color="primary" variant="contained" onClick={(e) => onLoginClicked(e)}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Dialog
        open={loading}
        PaperProps={{ className: classes.dialog }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
      </Dialog>
      <Dialog
        open={!!error}
        onClose={() => setError('')}
        PaperProps={{ className: classes.errorDialog }}
      >
        <Typography variant="h6" className={classes.errorDialogTitle}>Oops!</Typography>
        <DialogContent className={classes.errorDialogContent}>
          <DialogContentText variant="body1">{error}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '0' }}>
          <Button className={classes.dialogBtn} onClick={() => setError('')} color="primary" variant="contained">Try again</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default LoginView;
