import React, { useState, useEffect } from 'react';
import { makeStyles, Dialog, Fade } from '@material-ui/core';

import EmptyCardsView from './EmptyCardsView';
import CardsListView from './CardsListView';

const useStyles = makeStyles(({
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  }
}));

const sortCardsPrimaryFirst = (arr) => {
  return arr.sort((a, b) => {
    if (a.primary) {
      return -1;
    } if (b.primary) {
      return 1;
    }
    return 0;
  });
};

const Payment = () => {
  const classes = useStyles();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const saveCardHandler = async (card) => {
    // console.log('new card', card);
    const c = [...cards, card];
    setCards(sortCardsPrimaryFirst(c));
  };

  return (
    <div>
      {
        loading && (
          <Dialog
            open={loading}
            PaperProps={{ className: classes.dialog }}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
          </Dialog>
        )
      }
      {
        !loading && (cards.length > 0 ? (
          <Fade in={!loading}>
            <CardsListView data={cards} onAddCard={saveCardHandler} />
          </Fade>
        ) : (
          <Fade in={!loading}>
            <EmptyCardsView onAddCard={saveCardHandler} />
          </Fade>
        ))
      }
    </div>
  );
};

export default Payment;
