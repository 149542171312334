import moment from 'moment';

const isValidExpiry = (value) => {
  const expiry = moment(value, 'MM/YY');
  const today = moment();
  return expiry.isValid() && (today < expiry.add(1, 'months'));
};

const getBrandIcon = (brand) => {
  let brandIcon = '';
  if (brand.toString().toLowerCase() === 'visa') brandIcon = '/images/visa.svg';
  if (brand.toString().toLowerCase() === 'mastercard') brandIcon = '/images/mastercard.svg';
  return brandIcon;
};

const getBrandImage = (brand) => {
  let brandImg = '';
  if (brand.toLowerCase() === 'visa') brandImg = '/images/visa.png';
  if (brand.toLowerCase() === 'mastercard') brandImg = '/images/mastercard.png';
  return brandImg;
};

const getBrandImageFromCardNumber = (value) => {
  let brandImg = '';
  if (value.startsWith('4')) brandImg = '/images/visa.png';
  if (value.startsWith('5')) brandImg = '/images/mastercard.png';
  return brandImg;
};

const getBrandFromCardNumber = (value) => {
  let brand = '';
  if (value.startsWith('4')) brand = 'Visa';
  if (value.startsWith('5')) brand = 'Mastercard';
  return brand;
};

export default {
  isValidExpiry,
  getBrandIcon,
  getBrandImage,
  getBrandImageFromCardNumber,
  getBrandFromCardNumber,
};
