import axios from 'axios';

const { REACT_APP_MAGPIE_URL, REACT_APP_MAGPIE_PUBLIC_KEY } = process.env;

const getHeaders = () => {
  const authToken = btoa(`${REACT_APP_MAGPIE_PUBLIC_KEY}:`);
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${authToken}`,
  };
};

const createToken = async (card) => {
  try {
    const response = await axios.post(
      `${REACT_APP_MAGPIE_URL}/tokens`,
      card,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
};

const charge = async (token, amount = 10000) => {
  try {
    const response = await axios.post(
      `${REACT_APP_MAGPIE_URL}/charges`,
      { token, amount },
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
};

const getCharge = async (chargeId) => {
  try {
    const response = await axios.get(
      `${REACT_APP_MAGPIE_URL}/charges/${chargeId}`,
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
};

export default {
  createToken,
  charge,
  getCharge,
};
