import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 74
    }
  },
  palette: {
    background: {
      default: '#ffffff'
    },
    primary: {
      main: '#ff0008',
      dark: '#d90007'
    },
    secondary: {
      main: '#6cd3c9',
    },
  },
  typography: {
    fontFamily: '"Sharp Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  }
});

export default theme;
