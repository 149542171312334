import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    alignItems: 'center',
    minHeight: '64px',
    paddingBottom: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  back: {
    color: theme.palette.grey[900],
    marginLeft: '-10px',
  },
  icon: {
    textAlign: 'center',
  },
  title: {
    fontWeight: '600',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-48px',
      flex: '1 0 auto',
      textAlign: 'center',
    }
  }
}));

const Header = ({ title }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton onClick={() => navigate(-1)} className={classes.back} aria-label="back" component="span">
        <ArrowBackIos className={classes.icon} />
      </IconButton>
      <Typography className={classes.title} variant="h5">{title}</Typography>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired
};

export default Header;
