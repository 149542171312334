import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, Dialog } from '@material-ui/core';

import Page from 'src/components/Page';
import Header from 'src/components/Header';
import Magpie from 'src/api/Magpie';

const useStyles = makeStyles(({
  dialog: {
    padding: '1rem',
    borderRadius: '8px',
  },
  loadingImage: {
    animation: 'spin 4s linear infinite',
    height: 60,
    width: 60,
  }
}));

const BankAuthView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { card } = location.state;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initiate3ds = async () => {
      setLoading(true);
      try {
        const { data } = await Magpie.charge(card.id);
        // console.log('response', data);
        if ('error' in data) {
          navigate('/payment/3dsecure/failed', { state: { card } });
        } else {
          window.location.href = data.url;
        }
      } catch (err) {
        console.log(err);
      }
    };

    initiate3ds();
    return () => {
      setLoading(false);
    };
  }, [navigate, card]);

  return (
    <Page title="Singlife - Bank Authentication">
      <Header title="Bank 3D Secure Process" />
      <Dialog
        open={loading}
        PaperProps={{ className: classes.dialog }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <img className={classes.loadingImage} src="/images/singlife-icon.png" alt="loading" />
      </Dialog>
    </Page>
  );
};

export default BankAuthView;
