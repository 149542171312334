import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, makeStyles, Typography, Divider, Card, CardContent, Checkbox
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import Page from 'src/components/Page';
import Header from 'src/components/Header';

const useStyles = makeStyles((theme) => ({
  contentBody: {
    width: '100%',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '1rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  label: {
    fontWeight: '400',
  },
  value: {
    fontWeight: '600',
  },
  rowBtn: {
    fontWeight: '600',
    marginRight: '-1rem',
  },
  subtitle: {
    fontWeight: '600',
    marginBottom: '1rem',
  },
  btn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    padding: '12px 16px',
    marginTop: '0.75rem',
  },
  divider: {
    margin: '1.5rem 0',
  },
  notice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  noticeIcon: {
    alignSelf: 'flex-start',
    height: '20px',
  },
  noticeBody: {
    flex: '1 1 auto',
    paddingLeft: '1rem',
  },
  noticeTitle: {
    fontWeight: '600',
    marginBottom: '0.25rem',
  },
  noticeDesc: {
    display: 'block',
    fontWeight: '400',
  },
  card: {
    borderColor: '#d9e1ee',
    backgroundColor: '#d9e1ee44',
    borderRadius: 4,
    marginBottom: '1.5rem',
  },
  cardContent: {
    padding: '1rem !important',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  checkbox: {
    alignSelf: 'flex-start',
    marginRight: '1rem',
    marginLeft: '-9px',
    marginTop: '-10px',
  },
  declarationBody: {
    display: 'inline-block',
  },
  actionContainer: {
    width: '100%',
    padding: '0',
    marginTop: '1.5rem',
  },
}));

const ReviewDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onPayClick = (e) => {
    e.preventDefault();
    navigate('/payment');
  };

  return (
    <Page title="Singlife - Purchase Review">
      <Header title="Review the details below before proceeding" />
      <div className={classes.contentBody}>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label} style={{ fontWeight: '600' }}>
            Coverage
          </Typography>
          <Button color="primary" className={classes.rowBtn}>
            Edit
          </Button>
        </div>
        <Typography variant="body1" align="center" className={classes.subtitle}>
          You&apos;re covered for Dengue &amp; Covid-19 diagnosis and hospitalization.
        </Typography>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Plan
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Individual
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Coverage Level
          </Typography>
          <Typography variant="subtitle2" className={classes.value} style={{ textTransform: 'uppercase' }}>
            Bronze
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Total Benefit Amount
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Php 140,500
          </Typography>
        </div>
        <Button fullWidth variant="outlined" color="primary" className={classes.btn}>
          View Benefits
        </Button>
        <Divider className={classes.divider} />
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label} style={{ fontWeight: '600' }}>
            Payment Plan
          </Typography>
          <Button color="primary" className={classes.rowBtn}>
            Edit
          </Button>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Premium
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Php 300/year
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Next Due Date
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Jan 5, 2021
          </Typography>
        </div>
        <div className={classes.notice} style={{ paddingTop: '0.5rem' }}>
          <ErrorOutline className={classes.noticeIcon} />
          <div className={classes.noticeBody}>
            <Typography variant="caption" className={classes.noticeDesc}>
              Your plan will be auto-renew by Jan 5, 2021 and your renewal premium will
              {' '}
              be charged from your registered card.
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label} style={{ fontWeight: '600' }}>
            Profile Details
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Name
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Angelica Villaflor
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Date of Birth
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Feb 7, 1986
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Gender
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Female
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Nationality
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            Filipino
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Mobile
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            0917 123 4567
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label}>
            Email
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            angvillaflor@gmail.com
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.row}>
          <Typography variant="subtitle2" className={classes.label} style={{ fontWeight: '600' }}>
            Beneficiary
          </Typography>
        </div>
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Typography variant="caption" component="p" style={{ fontWeight: '600', marginBottom: '0.5rem' }}>
              Set as default
            </Typography>
            <Typography variant="caption" style={{ display: 'block', fontWeight: '400' }}>
              By default, policy benefits will be paid following the
              {' '}
              <span className={classes.link}>standard order</span>
              {' '}
              of beneficiaries. You may contact us at
              {' '}
              <span className={classes.link}>help@singlife.com</span>
              {' '}
              to name specific beneficiary/ies after purchase.
            </Typography>
          </CardContent>
        </Card>
        <Divider className={classes.divider} />
        <div className={classes.notice}>
          <ErrorOutline className={classes.noticeIcon} />
          <div className={classes.noticeBody}>
            <Typography variant="caption" component="p" className={classes.noticeTitle}>
              Cancellation
            </Typography>
            <Typography variant="caption" className={classes.noticeDesc}>
              You will get a full refund when you choose to cancel your policy within
              {' '}
              15 days of purchase.
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.row}>
          <Checkbox color="primary" className={classes.checkbox} />
          <div className={classes.declarationBody}>
            <Typography variant="subtitle2" className={classes.label} component="p" style={{ fontWeight: '600', marginBottom: '1rem' }}>
              Declaration
            </Typography>
            <Typography variant="caption" className={classes.label} component="p" style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
              I am currently not undergoing, have not received in the past 3 months nor plan to
              {' '}
              receive any medical treatments; and am not a &lsquo;
              <span className={classes.link}>confirmed COVID-19 case</span>
              &rsquo; or other equivalent classification as defined by PhilHealth.
            </Typography>
            <Typography variant="caption" className={classes.label} component="p" style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
              I have read and agree to the
              {' '}
              <span className={classes.link}>Terms &amp; Conditions</span>
              ,
              {' '}
              <span className={classes.link}>Exclusions &amp; Limitations</span>
              and
              {' '}
              <span className={classes.link}>Acknowledgement &amp; Declarations</span>
              {' '}
              of this product.
            </Typography>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <Button className={classes.btn} fullWidth variant="contained" color="primary" onClick={(e) => onPayClick(e)}>Confirm and pay</Button>
        </div>
      </div>
    </Page>
  );
};

export default ReviewDetails;
